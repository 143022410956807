.fb-container{
    display: flex;
    flex-direction: column;
align-items: center;
}

.fb-post{
    /* border: 1px solid red; */
}

.fb-title{
    text-align: center;
    margin: 2rem;
    font-size: clamp(2.1rem, 7vw, 3rem);
    /* color: #fff; */
    background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}