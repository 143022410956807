.container-instagram {
  margin: 0 auto;
  max-width: 60%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 5px;
  padding: 15px;
}

.container-instagram img {
  width: 100%;
  height: auto;
  display: block;
  transition: all 100ms ease-out;
}

.container-instagram img:hover {
  transform: scale(1.04);
}
.instagram-title {
  text-align: center;
  padding: 2rem;
  font-size: clamp(2.1rem, 7vw, 3rem);
  /* color: #fff; */
  background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.instagram-text {
  text-align: center;
  padding-bottom: 2rem;
  font-size: clamp(1.2rem, 7vw, 1.5rem);
  color: #000;
  text-decoration: none;
  margin: 0 auto;
  font-weight: 600;
}

.video_frame{
  width: 350px    !important;
  height: 350px   !important;
}


.videoWrapper {
  position: relative;
  padding-bottom: 100%; /* 16:9 */
  height: 0;
  background: linear-gradient(90deg, #fed693, #ca7100);
}
.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .container-instagram {
    max-width: 100%;
  }
}
