body {
  margin: 0;
  /* color: #fff; */
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;

}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 1rem;
}


.section {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}
.menu {
  padding: 5rem 0;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: clamp(2.1rem, 7vw, 3rem);
  /* color: #fff; */
  background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-container {
  margin-bottom: 4rem;
  display: flex;
  justify-content: center;
  flex-wrap:wrap;
}

.filter-btnbeauty {
  background: transparent;
  border-color: transparent;
  margin: 0 1rem;
  padding: 0.375rem 0.75rem;
  /* color: #fff; */
  cursor: pointer;
  transition: all 0.3s linear;
  border-radius: 0.25rem;

  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 2px;
  text-transform: uppercase;

  background: -webkit-linear-gradient(135deg, #fed693, #ca7100);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.active {
  border-bottom: 2px  #fed693 solid;
}

.section-center-beauty {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
  display: grid;
  gap: 0rem 4rem;
  justify-items: center;
  /* border: 1px solid green; */
}

.menu-item-beauty {
  display: flex;
  gap: 1rem 1rem;
  /* border: 1px solid red; */
}

.left-text-beauty{
  /* border: 1px solid green; */
  min-width: 470px;
}

.item-infobeauty header {
  display: flex;
  justify-content: space-between;
  color: #fff;
  /* border-bottom: 0.5px dotted hsl(210, 22%, 49%); */
  margin-top: 2rem;
}
.item-infobeauty h4 {
  margin-bottom: 0rem;
  font-size: 1.3rem;
  color: #c59d5f;
}
.price {
  color: #c59d5f;
  /* border: 1px solid green; */
  min-width: 100px;
  text-align: right;
}


.item-text {
  margin-bottom: 1rem;
  padding-top: 1rem;
}


@media screen and (min-width: 390px) {
  .menu-item-beauty {
    grid-template-columns: 225px 1fr;
        /* border: 1px solid green; */

    /* gap: 0 1.25rem; */
    max-width: 40rem;
  }

  .left-text-beauty{
    /* border: 1px solid green; */
    min-width: 250px;
  }

}
@media screen and (min-width: 1200px) {
  .section-center-beauty {
    width: 95vw;
    grid-template-columns: 1fr 1fr;
  }

  .left-text-beauty{
    /* border: 1px solid green; */
    min-width: 470px;
  }

}
